import Css from "./style.module.scss";

import DashboardPageDesktop from "nlib/pages/DashboardPage";
import React from "react";

const DashboardPage = () => {
  return (
    <DashboardPageDesktop className={Css.dashboardPage} />
  );
};

export default React.memo(DashboardPage);
